import { Controller } from "stimulus"
import ClassicEditor from "ckeditor5/packages/ckeditor5-build-classic"
import $ from 'jquery'

export default class extends Controller {

  connect() {
    console.log(this.element)
    ClassicEditor
      .create( this.element )
      .catch( error => {
        console.error( error );
      } );
  }
}
