import { lightpink } from "color-name";
import { Controller } from "stimulus";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.min.css";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "select",
    "input",
    "template",
    "dragItem",
    "searchModal",
    "newContentId",
    "selected",
    "save",
  ];
  static values = { url: String };

  connect() {
    const controller = this;
    TomSelect.define("no_close", function () {
      this.close = function () {};
    });
    this.tomSelect = new TomSelect(this.selectTarget, {
      plugins: ["checkbox_options", "no_close"],
      valueField: "id",
      labelField: "name",
      searchField: "name",
      preload: true,
      controlInput: this.inputTarget,
      dropdownParent: "#dropdownParent",
      // fetch remote data
      load: function (query, callback) {
        var url =
          controller.urlValue +
          "&pagination=false&search=" +
          encodeURIComponent(query);
        fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((json) => {
            callback(json);
          })
          .catch(() => {
            callback();
          });
      },
      // custom rendering functions for options and items
      render: {
        option: function (item, escape) {
          return `<label class="select-item form-group-v">
                    <div class="grid-x item-body">
                      <div class="cell shrink item-img">
                        <div class="session-img-wrapper" style="background-image: url(${
                          item.cover_image
                        })"></div>
                      </div>
                      <div class="cell auto item-main-content">
                        <h5 class="title">${item.name}</h5>
                        <div class="item-tag-group">
                          <div class="item-tag">
                            <span>${item.sport}</span>
                          </div>
                            ${item.age_groups
                              .map(
                                (ageGroup) =>
                                  '<div class="item-tag"><div class="item-tag-icon icon-age"></div><span>' +
                                  ageGroup.title +
                                  "</span></div>"
                              )
                              .join("")}
                          <div class="item-tag">
                            <i class="far fa-play-circle"></i>
                            <span>${item.duration.split(":")[0]} hours ${
            item.duration.split(":")[1]
          } minutes</span>
                          </div>
                        </div>
                      </div>
                      <div class="cell shrink item-fav" data-controller="favourite" data-favourite-url-value="/favourites">
                        <a onclick="event.stopPropagation();" class="pure-icon-button small${
                          item.favourite ? " is-active" : ""
                        }" data-action="click->favourite#favourite" data-target-id="${
            item.id
          }" data-target-type="${item.type}"><i class="far fa-heart"></i></a>
                      </div>
                    </div>
                  </label>`;
        },
        item: function (item, escape) {
          let template =
            controller.templateTarget.content.cloneNode(true).firstChild;
          let linkId = controller.templateTarget.dataset.id;
          let regexp = linkId ? new RegExp(linkId, "g") : null;
          let uniqueIndex = new Date().getTime();

          template.querySelector(
            "input[data-tom-select-content-target='newContentId']"
          ).value = item.id;
          template.querySelector(
            "div[data-tom-select-content-target='backgroundImage']"
          )
            ? (template.querySelector(
                "div[data-tom-select-content-target='backgroundImage']"
              ).style.backgroundImage = `url('${item.cover_image}')`)
            : null;
          template.querySelector(".display-title").innerHTML = item.name;

          return template.outerHTML.replace(regexp, uniqueIndex);
        },
      },
    });

    let myModal = new Modal(this.searchModalTarget);

    this.saveTarget.addEventListener("click", () => {
      this.getAllItem();
      this.tomSelect.clear(true);
      this.selectedTarget.innerHTML = "0";
      myModal.hide();
    });

    this.tomSelect.on("change", (e) => {
      this.selectedTarget.innerHTML = e.split(",").length;
    });
  }

  getAllItem() {
    this.tomSelect.items.forEach((value) => {
      this.tomSelect.getItem(value)
        ? this.dragItemTarget.appendChild(
            this.tomSelect.getItem(value).cloneNode(true)
          )
        : null;
    });
  }

  handleRemoveClick(e) {
    // Prevent the browser from following the URL.
    e.preventDefault();

    let link = e.target;
    // Find the parent wrapper for the set of nested fields.
    let fieldParent = link.closest(".nested-fields");
    // If there is a parent wrapper, find the hidden delete field.
    let deleteField = fieldParent
      ? fieldParent.querySelector('input[type="hidden"]')
      : null;
    // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
    if (deleteField) {
      deleteField.value = 1;
      fieldParent.style.display = "none";
    }
  }
}
