import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []
  static values = { url: String }

  connect(){

  }

  favourite(e){
    const element = e.currentTarget;
    e.preventDefault()

    fetch(this.urlValue, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ target_id: element.dataset.targetId, target_type: element.dataset.targetType })
    }).then(response => {
      if(response.ok){
        if(element.classList.contains('is-active')){
          element.classList.remove('is-active')
        } else {
          element.classList.add('is-active')
        }
      }
    })
  }
}