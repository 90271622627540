import {Controller} from "stimulus";
import $ from "jquery";

export default class extends Controller
{
  static targets = ["wrapper"];

  initialize()
  {
    this.checkRadioDefault();
  }

  connect()
  {
    this.buttonClick();
  }

  buttonClick()
  {
    let wrapper = $(this.wrapperTarget);
    let that = this;
    wrapper.find(".checkbox").on("click", function(e)
    {
      that.checkRadioDefault();
    });
  }

  checkRadioDefault()
  {
    let wrapper = $(this.wrapperTarget);

    wrapper.find(".checkbox").each(function(index)
    {
      let selector = $(this).find("label");
      if ($(this).find("input").prop("checked") === true)
      {
        wrapper.find(selector).addClass("is-active");
      }
      else
      {
        wrapper.find(selector).removeClass("is-active");
      }
    });
  }
}
