// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "foundation-sites";
import "controllers";
// import "@lottiefiles/lottie-player";
require("jquery");
require("jquery/src/jquery");
// require("nested_form")
// require("@nathanvda/cocoon")
// import Sortable from 'sortablejs';
// import 'select2'
// import 'select2/dist/css/select2.css'

Rails.start();
ActiveStorage.start();

const images = require.context("../images", true);
import $ from "jquery";

$(function () {
  $("a.add_fields")
    .data("association-insertion-position", "before")
    .data("association-insertion-node", "this");
});

/* function define field */
// foo function comm ...
// var foo = {
//   init() {
//     console.log('Test Content');
//   }
// };
