import { Controller } from "stimulus";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.min.css";

export default class extends Controller {
  static values = {
    url: String,
    licensenumber: Number,
  };

  connect() {
    console.log(this.licensenumberValue);
    var config = {
      plugins: ["remove_button"],
      maxItems: this.licensenumberValue,
      render: {
        option: function (data, escape) {
          return `<div><img class="me-2" style='height: 20px' src="${data.avatarUrl}">${data.text}</div>`;
        },
        item: function (item, escape) {
          return `<div><img class="me-2" style='height: 20px' src="${item.avatarUrl}">${item.text}</div>`;
        },
      },

      plugins: {
        remove_button: {
          title: "Remove this item",
        },
      },
    };
    new TomSelect(this.element, config);
  }
}
