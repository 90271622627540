import { Controller } from "stimulus"
import TomSelect from 'tom-select'
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ['select']
  static values = { url: String }

  connect(){
    const controller = this;
    var tomSelect = new TomSelect(this.selectTarget, {
      plugins: {
        remove_button:{
          title:'Remove this item',
        }
      },
      persist: false,
      createOnBlur: true,
      create: true,
      onOptionAdd: function(value) {
        var params = new FormData();
        params.append('name', value);
        Rails.ajax({
                    type: 'POST',
                    url: controller.urlValue,
                    data: params,
                    success: (_data, _status, xhr) => {
                      tomSelect.input.lastChild.value = xhr.getResponseHeader('ID')
                    }
                  });
      }
    });
  }

  
}