import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "role", "info", "length", "number", "letter" ]
  static values = { show: Boolean }

  connect() {
    if (this.showValue) {
      this.roleTarget.hidden = true;
      this.infoTarget.hidden = false;
    } else {
      this.infoTarget.hidden = true;
    }
  }

  next(){
    this.roleTarget.hidden = true;
    this.infoTarget.hidden = false;
  }

  backTo(e){
    e.preventDefault();
    this.roleTarget.hidden = false;
    this.infoTarget.hidden = true;
  }

  password(e){
    if (e.target.value.match(/.{8,}/)) {
      $(this.lengthTarget).addClass('is-active');
    } else {
      $(this.lengthTarget).removeClass('is-active');
    }

    if (e.target.value.match(/\d/)) {
      $(this.numberTarget).addClass('is-active');
    } else {
      $(this.numberTarget).removeClass('is-active');
    }

    if (e.target.value.match(/[A-Z]/)) {
      $(this.letterTarget).addClass('is-active');
    } else {
      $(this.letterTarget).removeClass('is-active');
    }
  }
}