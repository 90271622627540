import { Controller } from "stimulus"
import Sortable from 'sortablejs';


export default class extends Controller {
  static targets = ['dragItem', 'position', 'sortable']

  connect(){
    this.sortResult = Sortable.create(this.dragItemTarget, { 
      animation: 150,
      handle: '.drag-handle',
      ghostClass: "sortable-ghost",
      chosenClass: "sortable-chosen",
      dragClass: "sortable-drag",
      draggable: ".draggable"
    });
  }

  submitForm(e){
    this.positionTargets.forEach((e, index) => {
      e.value = index;
    })
  }
}