import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["role", "sport"];

  connect() {
    this.roleTargets.forEach((element) => {
      element.addEventListener("change", (e) => {
        if (e.target.parentElement.textContent === "School") {
          this.sportTargets.forEach((sport) => {
            sport.checked = false;
            sport.disabled = true;
            sport.classList.add("disabled");
          });
        } else {
          this.sportTargets.forEach((sport, index) => {
            sport.classList.remove("disabled");
            sport.disabled = false;
            if (index === 0) sport.checked = true;
          });
        }
      });

      if (element.checked && element.parentElement.textContent === "School") {
        this.sportTargets.forEach((sport) => {
          sport.checked = false;
          sport.disabled = true;
          sport.classList.add("disabled");
        });
      } else if (
        element.checked &&
        element.parentElement.textContent === "Club"
      ) {
        this.sportTargets.forEach((sport) => {
          sport.classList.remove("disabled");
          sport.disabled = false;
        });
      }
    });
  }
}
