import { Controller } from "stimulus"
import Plyr from 'plyr';

export default class extends Controller {
  static targets = ['video']
  connect(){
    this.videoTargets.forEach(e => {
      new Plyr(e);
    });
  }
}