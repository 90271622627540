import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    let controller = this;

    fetch("/setting/customer-balance", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    })
      .then((data) => data.json())
      .then((r) => {
        controller.element.innerHTML = `Credit on account: $${r.balance}`;
      });
  }
}
