import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["handle", "triggeredButton"];

  connect() {
    $(this.handleTarget).on('click', function () {
      console.log('clicked!', $('.triggeredButton'))
      $('.triggeredButton').trigger('click');
    });
  }
}
