import { Controller } from "stimulus";
import "slick-carousel";
import $ from "jquery";
import fullscreen from "../images/fullscreen.png";

export default class extends Controller {
  static targets = ["mainCarousel", "subCarousel", "poster"];
  connect() {
    function requestFullScreen(element) {
      // Supports most browsers and their versions.
      var requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen;

      if (requestMethod) {
        // Native full screen.
        requestMethod.call(element);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // Older IE.
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript !== null) {
          wscript.SendKeys("{F11}");
        }
      }
    }

    if (this.hasMainCarouselTarget && this.hasSubCarouselTarget) {
      $(this.mainCarouselTarget).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        draggable: false,
        asNavFor: $(this.subCarouselTarget),
      });

      $(this.subCarouselTarget).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: $(this.mainCarouselTarget),
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        draggable: false,
        prevArrow:
          "<div class='slick-prev-icon' id = 'prev'><i class='far fa-angle-left'></i></div>",
        nextArrow:
          "<div class='slick-next-icon' id = 'next'><i class='far fa-angle-right'></i></div>",
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
              infinite: true,
            },
          },
        ],
      });
    }
    require("@lottiefiles/lottie-player");

    $("document").ready(function () {
      // $("lottie-player").width($(".slider-item").width());
      // $("lottie-player").height($(".slider-item").outerHeight() - 60);
      const lottiePlayer = document.querySelectorAll("lottie-player");
      const poster = document.querySelectorAll("div.lottie-poster");

      if (poster.length !== 0) {
        poster.forEach((element) => {
          element.addEventListener("click", (e) => {
            element.hidden = true;
            let player = element.parentNode.querySelector("lottie-player");
            player.hidden = false;
            player.play();
          });
        });
      } else {
        lottiePlayer.forEach((player) => {
          player.hidden = false;
        });
      }

      if (lottiePlayer.length !== 0) {
        lottiePlayer.forEach((player) => {
          let lottieControls =
            player.shadowRoot.querySelector("#lottie-controls");
          let buttonEl = document.createElement("button");
          let image = document.createElement("img");
          buttonEl.addEventListener("click", () => {
            if (window.innerHeight == screen.height) {
              document.exitFullscreen();
            } else {
              requestFullScreen(player);
            }
          });
          image.src = fullscreen;
          image.height = 15;
          lottieControls.style =
            "grid-template-columns: 32px 32px 1fr 32px 32px;";
          buttonEl.style = "align-items:center;";
          buttonEl.append(image);
          lottieControls.append(buttonEl);
        });
      }
    });
  }
}
