import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["wrapper"];

  initialize() {
    let wrapper = $(this.wrapperTarget);
    let radioButtonGroup = wrapper.find(".radio-button-group");
    if (radioButtonGroup.is(":empty")) {
      this.renderRadioButtons();
      this.checkRadioDefault();
    }
  }

  connect() {
    this.buttonClick();
  }

  renderRadioButtons() {
    let wrapper = $(this.wrapperTarget);
    let radioButtonGroup = wrapper.find(".radio-button-group");
    let hasAnimation = wrapper.hasClass("animated");
    wrapper.find(".radio").each(function (index) {
      let radioText = "<span>" + $(this).find("label").text() + "</span>";
      let radioId = $(this).find("input").attr("id");
      let appendHtml = "";
      let buttonClass = "button large white " + $(this).find("input").val();

      if (
        radioButtonGroup.hasClass("coming_sport") &&
        radioButtonGroup.hasClass($(this).find("label").text().toLowerCase())
      ) {
        appendHtml += "<div class='" + buttonClass + "' for='" + radioId + "'>";
        appendHtml += "<div class='button-left'></div>";
        appendHtml +=
          "<div class='button-right'><span>" +
          radioText +
          "</span>" +
          "<div class='coming-soon'>Coming soon</div>";
        appendHtml += "</div>";
      } else if (wrapper.hasClass("second-button-disabled") && index == 1) {
        appendHtml += "<div class='" + buttonClass + "' for='" + radioId + "'>";
        appendHtml += "<div class='button-left'></div>";
        appendHtml +=
          "<div class='button-right'><span>" +
          radioText +
          "</span>" +
          "<span>Coming soon</span>";
        appendHtml += "</div>";
      } else {
        appendHtml += "<div class='" + buttonClass + "' for='" + radioId + "'>";
        appendHtml += radioText + "</div>";
      }
      // if (wrapper.hasClass('second-button-disabled') && (index == 1)) {
      //   appendHtml += "<div class='" + buttonClass + "' for='" + radioId + "'>";
      //   appendHtml += "<div class='button-left'></div>"
      //   appendHtml += "<div class='button-right'><span>" + radioText + "</span>" + "<span>Coming soon</span>"
      //   appendHtml += "</div>"
      // } else {
      //   appendHtml += "<div class='" + buttonClass + "' for='" + radioId + "'>";
      //   appendHtml += radioText + "</div>";
      // }
      radioButtonGroup.append(appendHtml);
      if (hasAnimation) {
        appendAnimation(
          radioId,
          $(this).find("input").val(),
          $(this).parents(".radio-button-wrapper")
        );
      }
    });

    function appendAnimation(radioId, buttonClass, wrapper) {
      let button = wrapper.find(".radio-button-group ." + buttonClass);
      let lottie = wrapper.find(`[data-toggle=${radioId}]`);
      let $lottie = lottie.clone();
      button.append($lottie);
    }
  }

  buttonClick() {
    let wrapper = $(this.wrapperTarget);
    let hasAnimation = wrapper.hasClass("animated");
    let radioButtonGroup = wrapper.find(".radio-button-group");
    radioButtonGroup.find(".button").on("click", function () {
      radioButtonGroup.find(".button").removeClass("is-active");
      if (hasAnimation) {
        radioButtonGroup.find("lottie-player").each(function () {
          $(this)[0].stop();
        });
      }

      if ($(this).find("div").hasClass("coming-soon")) {
        console.log($(this).find(".coming-soon"));
      } else {
        $(this).addClass("is-active");
      }

      if (hasAnimation) {
        $(this).find("lottie-player")[0].play();
      }
      let selector = "input#" + $(this).attr("for");
      wrapper.find(selector).prop("checked", true);
      var event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      wrapper.find(selector)[0].dispatchEvent(event);
      if ($("[data-trigger-disabled-button]")) {
        if ($(this).find("div").hasClass("coming-soon")) {
          $("[data-trigger-disabled-button]").prop("disabled", true);
        } else {
          $("[data-trigger-disabled-button]").prop("disabled", false);
        }
        // $("[data-trigger-disabled-button]").prop("disabled", false);
      }
    });
  }

  checkRadioDefault() {
    let wrapper = $(this.wrapperTarget);
    let input_id = wrapper.find("input:checked").prop("id");
    let selector = ".button[for=" + input_id + "]";
    wrapper.find(selector).addClass("is-active");
  }
}
