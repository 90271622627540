import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "addlink",
    "removelink",
    "nestedWrapper",
    "termsofUse",
    "termsCheckBox",
    "termsofUseEndWords",
    "submitButton",
  ];

  connect() {
    console.log("nested_form");

    // Avoid errors in drill edit page
    this.termsCheckBoxTarget.addEventListener("change", (checkBox) => {
      if (this.termsCheckBoxTarget.checked) {
        this.submitButtonTarget.disabled = false;
      } else {
        this.submitButtonTarget.disabled = true;
      }
    });
  }

  handleAddClick(e) {
    // Prevent the browser from following the URL.
    e.preventDefault();

    let link = e.target;
    // Save a unique timestamp to ensure the key of the associated array is unique.
    let time = new Date().getTime();
    // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
    let linkId = link.dataset.id;
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
    let regexp = linkId ? new RegExp(linkId, "g") : null;
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
    // Add the new markup to the form if there are fields to add.
    newFields ? link.insertAdjacentHTML("beforebegin", newFields) : null;

    this.termsofUseTarget.hidden = false;

    // Reset terms of use status
    this.termsCheckBoxTarget.checked = false;
    this.submitButtonTarget.disabled = true;

    this.updateTermsofUseText();
  }

  handleRemoveClick(e) {
    console.log("remove");
    // Prevent the browser from following the URL.
    e.preventDefault();

    let link = e.target;
    // Find the parent wrapper for the set of nested fields.
    let fieldParent = link.closest(".nested-fields");
    // If there is a parent wrapper, find the hidden delete field.
    let deleteField = fieldParent
      ? fieldParent.querySelector('input[type="hidden"]')
      : null;
    // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
    if (deleteField) {
      deleteField.value = 1;
      fieldParent.style.display = "none";
    }

    let nestedWrapper = fieldParent.closest(".nested-wrapper");
    let showedNestedFields = nestedWrapper.querySelectorAll(
      "div.nested-fields:not(.old-drill-content):not([style*='display: none'])"
    );
    // Only hide Terms of Use when all new added content fields are removed
    if (showedNestedFields.length === 0) {
      this.termsofUseTarget.hidden = true;
      this.submitButtonTarget.disabled = false;
    }

    this.updateTermsofUseText();
  }

  updateTermsofUseText() {
    let showedNestedFields = this.nestedWrapperTarget.querySelectorAll(
      "div.nested-fields:not(.old-drill-content):not([style*='display: none'])"
    );
    let termsofUserText = this.termsofUseEndWordsTarget;

    if (showedNestedFields.length > 1) {
      termsofUserText.textContent = "these contents";
    } else {
      termsofUserText.textContent = "this content";
    }
  }
}
