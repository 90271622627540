import { Controller } from "stimulus"


export default class extends Controller {
  initialize() {
    let mediatags = this.element.getElementsByTagName("oembed");
    for (let i = 0; i < mediatags.length; i++) {
      let videourl= mediatags[i].getAttribute('url');
      if (videourl.includes("www.youtube.com/watch?v=") || videourl.includes("https://youtu.be/")){
        if (videourl.search("=") != -1 ) {
          videourl = videourl.split("www.youtube.com/watch?v=")[1];
        }else{
          videourl = videourl.split("https://youtu.be/")[1];
        }
        mediatags[i].innerHTML =`<iframe src="https://www.youtube.com/embed/${videourl}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      }else{
        videourl = videourl.split("https://vimeo.com/")[1];
        mediatags[i].innerHTML =`<iframe src="https://player.vimeo.com/video/${videourl}" title="Vimeo video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      }
    }
  }
}
