import { Controller } from "stimulus"
import { loadStripe } from '@stripe/stripe-js';
import Rails from "@rails/ujs";
export default class extends Controller {

  static targets = [ 'couponCheckButton', 'payButton', 'priceInfo', 'billingName', 'paymentPage', 'infoPage', 'subscriptionPlan', 'quantity', 'subscriptionPrice', 'licenceNumber', 'paymentMethod', 'spinner', 'continueButton', 'termsCheckBox', 'privacyCheckBox', 'subscriptionPlan']
  static values = { customer: String, stripe: String, invoice: String, subscribe: String }

  async initialStripe(){
    this.stripe = await loadStripe(this.stripeValue);
    const controller = this
    let elements = this.stripe.elements();
    let style = {
      base: {
        color: "#000000",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#BEBEBE"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    this.cardNumber = elements.create('cardNumber', {
      style: style
    });
    let cardNumber = this.cardNumber

    let cardExpiry = elements.create('cardExpiry', {
      style: style
    });

    let cardCvc = elements.create('cardCvc', {
      style: style
    });

    cardNumber.mount('#card-number');
    cardExpiry.mount('#card-expiry');
    cardCvc.mount('#card-cvc');

    [cardNumber, cardExpiry, cardCvc].forEach(element => {
      element.on("change", function (event) {
        // Disable the Pay button if there are no card details in the Element
        controller.payButtonTarget.disabled = event.empty;
        document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
        // if(event.error) {
        //   $("#card-error").text(event.error.message);
        //   $(".form-group-v.strip-form-group").addClass('error');
        // }else {
        //   $("#card-error").text("");
        //   $(".form-group-v.strip-form-group").removeClass('error');
        // }
      });
    })
  }

  connect(){
    this.payButtonTarget.disabled = true;
    this.paymentPageTarget.hidden = true;
    this.continueButtonTarget.disabled = true;
    if (this.quantityTarget.value === 'individual') {
      this.item_quantity = 1
      this.subscriptionPlanTargets.forEach(e => {
        if (e.checked) {
          this.subscriptionPriceTarget.innerHTML = (e.dataset.defaultamount * this.item_quantity)/100; 
          this.default_interval = 0;
          this.defaultproductid = e.dataset.coachsingleproductid;
         
        }
      })
      
      var params = new FormData();
      params.append('productid', this.defaultproductid);
      params.append('interval', this.default_interval);
      params.append('coupon_code', $('#user_role_coupon_code').val());
      Rails.ajax({
        type: 'POST',
        url: '/subscriptions/retrieve-subscription-plans',
        data: params,
        success: (result) => {
          this.original_amount = result.original_amount
          this.subscription_plan_id = result.planid;
          this.local_subscription_plan_id = result.local_plan_id;
        }
      });

    } else if (this.quantityTarget.value === 'family') {
      this.item_quantity = 1
      
    } else {
      this.item_quantity = 1
      const quantity = this.item_quantity
      this.subscriptionPlanTargets.forEach(e => {
        if (e.checked) {
          this.subscriptionPriceTarget.innerHTML = (e.dataset.amount * quantity)/100 
        }
      })
    }
    if (this.hasLicenceNumberTarget) {
      this.licenceNumberTarget.innerHTML = this.quantityTarget.value
    }
   
    this.termsCheckBoxTarget.addEventListener("change", (checkBox) => {  
      if (this.termsCheckBoxTarget.checked && this.privacyCheckBoxTarget.checked) {
        this.continueButtonTarget.disabled = false;
      }else{
        this.continueButtonTarget.disabled = true;
      }
    })

    this.privacyCheckBoxTarget.addEventListener("change", (checkBox) => {  
      if (this.termsCheckBoxTarget.checked && this.privacyCheckBoxTarget.checked) {
        this.continueButtonTarget.disabled = false;
      }else{
        this.continueButtonTarget.disabled = true;
      }
    })

    this.initialStripe()
    this.retrieve_stripe_customer()
  }

  validateInput(input){
    if (input.length != 0){
      let error_message = "<small class='error'>can't be blank</small>"
      if(!input[0].value){
        var par = input.parent().parent()
        par.addClass("error")
        if (par.find('.error').length == 0){
          par.append(error_message)
        }
      } else if(input[0].value){
        var par = input.parent().parent()
        par.removeClass("error")
        par.find('.error').remove()
        return true
      }
    }
    else{
      return true
    }
  }

  continue(){
    const controller = this;
    this.couponCheck();
    this.validateInput($("#user_role_organisation_name"))
    this.validateInput($("#user_role_name"))

    if(this.validateInput($("#user_role_organisation_name")) && this.validateInput($("#user_role_name"))){
      if (this.subscriptionPlanTargets[1].checked){
        this.priceinfotag = this.subscriptionPriceTarget.innerHTML + '/month';
      }else{
        this.priceinfotag = this.subscriptionPriceTarget.innerHTML + '/year';
      }

      controller.priceInfoTarget.innerHTML = this.priceinfotag;
      this.infoPageTarget.hidden = true
      this.paymentPageTarget.hidden = false
    }
  }

  backTo(e){
    e.preventDefault();
    this.infoPageTarget.hidden = false
    this.paymentPageTarget.hidden = true
  }

  quantity(e){
    if($('#user_role_coupon_code').val() !== ''){
      $('#user_role_coupon_code').val('');
      document.querySelector("#coupon-error").textContent = 'Your coupon code has been withdrawn since you have changed the number of licences. Please apply the code again.';
    }
    

    if (e.target.value === 'individual') {
      this.item_quantity = 1

      this.subscriptionPlanTargets.forEach(e => {
        this.interval = 0;
        if (e.checked) {
          this.interval = this.interval + 1;
        }
      })

      var params = new FormData();
     
      // prefer to use the interval from subscription_plan(e)
      params.append('interval', this.interval);
      params.append('productid', e.target.dataset.coachsingleproductid);
      params.append('coupon_code', $('#user_role_coupon_code').val());
      
      Rails.ajax({
        type: 'POST',
        url: '/subscriptions/retrieve-subscription-plans',
        data: params,
        success: (result) => {
          this.original_amount = result.original_amount
          this.discount_price = result.amount
          this.subscriptionPriceTarget.innerHTML = result.amount/100;
          this.subscription_plan_id = result.planid;
          this.local_subscription_plan_id = result.local_plan_id;
        }
      });  

    } else if (e.target.value === 'family') {
      this.item_quantity = 1
      this.subscriptionPlanTargets.forEach(e => {
        this.interval = 0;
        if (e.checked) {
          this.interval = this.interval + 1;
        }
      })

      var params = new FormData();
     
      // prefer to use the interval from subscription_plan(e)
      params.append('interval', this.interval);
      params.append('productid', e.target.dataset.coachfamilyproductid);
      params.append('coupon_code', $('#user_role_coupon_code').val());
      
      Rails.ajax({
        type: 'POST',
        url: '/subscriptions/retrieve-subscription-plans',
        data: params,
        success: (result) => {
          this.discount_price = result.amount
          this.original_amount = result.original_amount
          this.subscriptionPriceTarget.innerHTML = result.amount/100;
          this.subscription_plan_id = result.planid;
          this.local_subscription_plan_id = result.local_plan_id;
        }
      });  

    } else {
      this.item_quantity = e.target.value  
    
      this.subscriptionPlanTargets.forEach(e => {
        this.interval = 0;
        if (e.checked) {
          this.interval = this.interval + 1;
        }
      })


      var params = new FormData();
      params.append('sequence', e.target.value);
      // prefer to use the interval from subscription_plan(e)
      params.append('interval', this.interval);
      params.append('productid', e.target.dataset.productid);
      params.append('coupon_code', $('#user_role_coupon_code').val());
      
      Rails.ajax({
        type: 'POST',
        url: '/subscriptions/retrieve-subscription-plans',
        data: params,
        success: (result) => {
          this.discount_price = result.amount;
          this.original_amount = result.original_amount
          this.subscriptionPriceTarget.innerHTML = result.amount/100;
          this.subscription_plan_id = result.planid;
          this.local_subscription_plan_id = result.local_plan_id;
        }
      });  
    }

    if (this.hasLicenceNumberTarget) {
      this.licenceNumberTarget.innerHTML = this.item_quantity
    }
  }

  couponCheck(e){
    if(e) e.preventDefault();
    var params = new FormData();
    params.append('coupon_code', $('#user_role_coupon_code').val().trim());
    params.append('price_info', this.original_amount/100);
    params.append('subscription_plan_id', this.subscription_plan_id);
    
    Rails.ajax({
      type: 'POST',
      url: '/subscriptions/coupon-check',
      data: params,
      success: (result) => {
        this.subscriptionPriceTarget.innerHTML = result.discounted_price;
        if (this.subscriptionPlanTargets[1].checked){
          this.priceInfoTarget.innerHTML = this.subscriptionPriceTarget.innerHTML + '/month';
        }else{
          this.priceInfoTarget.innerHTML = this.subscriptionPriceTarget.innerHTML + '/year';
        }
        document.querySelector("#coupon-error").textContent = ''
      },
      error: (error) => {
        this.subscriptionPriceTarget.innerHTML = error.original_price;
        if (this.subscriptionPlanTargets[1].checked){
          this.priceInfoTarget.innerHTML = this.subscriptionPriceTarget.innerHTML + '/month';
        }else{
          this.priceInfoTarget.innerHTML = this.subscriptionPriceTarget.innerHTML + '/year';
        }
        document.querySelector("#coupon-error").textContent = error.message;
        $('#user_role_coupon_code').val('')
      }
    });
  }

  subscription_plan(e){
    const controller = this;

    var params = new FormData();
    if (this.hasLicenceNumberTarget) {
      params.append('sequence', this.licenceNumberTarget.innerHTML);
      params.append('productid', e.target.dataset.productid);
    }else{
      if (this.quantityTargets[0].checked){
        params.append('productid', e.target.dataset.coachsingleproductid);
      }else{
        params.append('productid', e.target.dataset.coachfamilyproductid);
      }
      
    }
    $('#user_role_coupon_code').val('');
    params.append('interval', e.target.dataset.interval);
    // params.append('coupon_code', $('#user_role_coupon_code').val());
    
    Rails.ajax({
      type: 'POST',
      url: e.target.dataset.path,
      data: params,
      success: (result) => {
        this.discount_price = result.amount
        this.original_amount = result.original_amount
        this.subscriptionPriceTarget.innerHTML = result.amount/100;
        this.subscription_plan_id = result.planid;
        this.local_subscription_plan_id = result.local_plan_id;
      }
    });
    
    // consloe.log(this.subscription_plan_id);
    // this.subscription_plan_id = e.target.value
  }

  retrieve_stripe_customer(){
    const controller = this;
    fetch(this.customerValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then(response => response.json())
      .then(customer => {
        controller.customer_id = customer.id
      })
  }

  async retrieveUpcomingInvoice(subscriptionId, new_subscription_plan_id, quantity) {

    
    const response = await fetch(this.invoiceValue, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        subscription_id: subscriptionId,
        subscription_plan_id: new_subscription_plan_id,
        quantity: quantity,
      }),
    });
    const invoice = await response.json();
    this.invoice = invoice.invoice;
    return invoice;
  }

  createSubscription(paymentMethodId) {
    // console.log(Object.fromEntries(new FormData(this.element)))
    // new FormData(this.element).forEach((value, key) => data[key] = value);
    const controller = this;
    return (
      fetch(this.subscribeValue, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({payment_method_id: paymentMethodId}),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          controller.paymentMethodTarget.value = result.payment_method_id
          controller.subscriptionPlanTarget.value = this.local_subscription_plan_id
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        // .then(controller.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        // .then(controller.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(() => {
          // controller.element.append('subscription_plan_id', this.subscription_plan_id)
          controller.element.submit()
          // controller.onSubscriptionComplete
        })
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          controller.showError(error.error.message);
        })
      );
  }
    
  createPaymentMethod({ isPaymentRetry, invoiceId }) {
    const controller = this;
    let subscription_plan_id = this.subscription_plan_id
    let quantity = 1
    let billingName = this.billingNameTarget.value;
  
    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: controller.cardNumber,
        billing_details: {
          name: billingName,
        },
      })
      .then((result) => {
        if (result.error) {
          controller.showError(result.error.message);
        } else {
          if (isPaymentRetry) {
            // Update the payment method and retry invoice payment
            this.retryInvoiceWithNewPaymentMethod(
              customerId,
              result.paymentMethod.id,
              invoiceId,
              subscription_plan_id
            );
          } else {
            // Create the subscription
            controller.paymentMethodTarget.value = result.paymentMethod.id
            this.createSubscription(
              result.paymentMethod.id,
            );
          }
        }
      })
  }

  submitPayment(e){
    if (e.submitter.name != 'skip') {
      e.preventDefault()
      if (this.element.checkValidity()) {
        this.loading(true);
        if (this.paymentMethodTarget.value) {
          this.element.submit()
        } else {
          this.createPaymentMethod({});
        }
      } else {
        console.log('validation failed')
      }
    }
  }

  showError(errorMsgText) {
    this.loading(false);
    var errorMsg = document.querySelector("#card-error");
    errorMsg.textContent = errorMsgText;
    setTimeout(function() {
      errorMsg.textContent = "";
    }, 5000);
  };
  // Show a spinner on payment submission
  loading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.payButtonTarget.disabled = true;
      this.spinnerTarget.classList.add("is-paying");
      // document.querySelector("#spinner").classList.remove("hide");
      // document.querySelector("#button-text").classList.add("hide");
    } else {
      this.payButtonTarget.disabled = false;
      this.spinnerTarget.classList.remove("is-paying");
      // document.querySelector("#spinner").classList.add("hide");
      // document.querySelector("#button-text").classList.remove("hide");
    }
  };

  onSubscriptionComplete(subscription, paymentMethodId, subscription_plan_id){
    console.log(subscription)
  }

  // handlePaymentThatRequiresCustomerAction({
  //   subscription,
  //   invoice,
  //   subscription_plan_id,
  //   paymentMethodId,
  //   isRetry,
  // }) {
  //   if (subscription && subscription.status === 'active') {
  //     // subscription is active, no customer actions required.
  //     return { subscription, subscription_plan_id, paymentMethodId };
  //   }
  
  //   // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  //   // If it's a retry, the payment intent will be on the invoice itself.
  //   let paymentIntent = invoice
  //     ? invoice.payment_intent
  //     : subscription.latest_invoice.payment_intent;
  
  //   if (
  //     paymentIntent.status === 'requires_action' ||
  //     (isRetry === true && paymentIntent.status === 'requires_payment_method')
  //   ) {
  //     return this.stripe
  //       .confirmCardPayment(paymentIntent.client_secret, {
  //         payment_method: paymentMethodId,
  //       })
  //       .then((result) => {
  //         if (result.error) {
  //           // start code flow to handle updating the payment details
  //           // Display error message in your UI.
  //           // The card was declined (i.e. insufficient funds, card has expired, etc)
  //           throw result;
  //         } else {
  //           if (result.paymentIntent.status === 'succeeded') {
  //             // There's a risk of the customer closing the window before callback
  //             // execution. To handle this case, set up a webhook endpoint and
  //             // listen to invoice.paid. This webhook endpoint returns an Invoice.
  //             return {
  //               subscription_plan_id: subscription_plan_id,
  //               subscription: subscription,
  //               invoice: invoice,
  //               paymentMethodId: paymentMethodId,
  //             };
  //           }
  //         }
  //       });
  //   } else {
  //     // No customer action needed
  //     return { subscription, subscription_plan_id, paymentMethodId };
  //   }
  // }

  // handleRequiresPaymentMethod({
  //   subscription,
  //   paymentMethodId,
  //   subscription_plan_id,
  // }) {
  //   if (subscription.status === 'active') {
  //     // subscription is active, no customer actions required.
  //     return { subscription, subscription_plan_id, paymentMethodId };
  //   } else if (
  //     subscription.latest_invoice.payment_intent.status ===
  //     'requires_payment_method'
  //   ) {
  //     // Using localStorage to store the state of the retry here
  //     // (feel free to replace with what you prefer)
  //     // Store the latest invoice ID and status
  //     localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
  //     localStorage.setItem(
  //       'latestInvoicePaymentIntentStatus',
  //       subscription.latest_invoice.payment_intent.status
  //     );
  //     throw { error: { message: 'Your card was declined.' } };
  //   } else {
  //     return { subscription, subscription_plan_id, paymentMethodId };
  //   }
  // }
}