import { Controller } from "stimulus"
import Rails from "@rails/ujs";
export default class extends Controller {

  static targets = ['couponCheckButton','category', 'quantity', 'subscriptionPlan','category', 'subscriptionPrice', 'subscriptionPriceHint', 'couponError']
  
  connect(){
    const controller = this;
    this.subscription_plan_id = this.subscriptionPlanTarget.dataset.planid
  }

  admin_quantity(e){
    this.item_quantity = e.target.value  
    const controller = this;
      this.subscriptionPlanTargets.forEach(e => {
        this.interval = 0;
        if (e.checked) {
          this.interval = this.interval + 1;
        }
      })


      var params = new FormData();
      params.append('id', e.target.dataset.organisactionid);
      params.append('sequence', e.target.value);
      params.append('interval',  this.interval);
      params.append('productid', e.target.dataset.productid);
      
      Rails.ajax({
        type: 'POST',
        url: '/admin/organisations/retrieve-subscription-plans',
        data: params,
        success: (result) => {
          controller.subscriptionPlanTarget.value = result.local_plan_id
          this.original_amount = result.original_amount
        }
      });  
  }

  quantity(e){
    if($('#subscription_coupon_code').val() !== ''){
      $('#subscription_coupon_code').val('');
      this.couponErrorTarget.innerHTML = `<div class="warning small-margin-bottom-gutter with-icon" id="coupon-error" role="alert">Your coupon code has been withdrawn since you have changed the number of licences. Please apply the code again.</div>`;
      this.couponCheckButtonTarget.innerHTML = "Apply";
      this.couponCheckButtonTarget.disabled = false;
    }

    this.item_quantity = e.target.value  
    const controller = this;
      this.subscriptionPlanTargets.forEach(e => {
        this.interval = 0;
        if (e.checked) {
          this.interval = this.interval + 1;
        }
      })

      var params = new FormData();
      params.append('sequence', e.target.value);
      params.append('interval',  this.interval);
      params.append('productid', e.target.dataset.productid);
      
      Rails.ajax({
        type: 'POST',
        url: '/subscriptions/retrieve-subscription-plans',
        data: params,
        success: (result) => {
          this.original_amount = result.original_amount
          this.subscriptionPriceTarget.innerHTML = `${result.amount/100}`;
          controller.subscriptionPlanTarget.value = result.local_plan_id
          this.subscription_plan_id = result.planid
        }
      });  
  }

  category(e){
    if($('#subscription_coupon_code').val() !== ''){
      $('#subscription_coupon_code').val('');
      this.couponErrorTarget.innerHTML = `<div class="warning small-margin-bottom-gutter with-icon" id="coupon-error" role="alert">Your coupon code has been withdrawn since you have changed the payment. Please apply the code again.</div>`;
      this.couponCheckButtonTarget.innerHTML = "Apply";
      this.couponCheckButtonTarget.disabled = false;
    }
    const controller = this;
    
    this.subscriptionPlanTargets.forEach(e => {
      this.interval = 0;
      if (e.checked) {
        this.interval = this.interval + 1;
      }
    })
    var params = new FormData();
    params.append('id', e.target.dataset.coachid);
    params.append('interval',  this.interval);
    params.append('productid', e.target.dataset.productid);
    
    Rails.ajax({
      type: 'POST',
      url: e.target.dataset.url,
      data: params,
      success: (result) => {
        controller.subscriptionPlanTarget.value = result.local_plan_id;
        this.subscriptionPriceTarget.innerHTML = result.amount/100;
        this.original_amount = result.original_amount
        this.subscription_plan_id = result.planid 
      }
    });  
  }

  admin_subscription_plan(e){
    const controller = this;
    
    var params = new FormData();
    params.append('id', e.target.dataset.organisactionid);
    if(this.hasQuantityTarget){
      params.append('sequence', this.quantityTarget.value);
      params.append('productid', e.target.dataset.productid);
    }else{
   
      if(this.categoryTargets[1].checked){
        this.coach_product_id = e.target.dataset.coachsingleproductid;
      }else{
        this.coach_product_id = e.target.dataset.coachfamilyproductid;
      }
      params.append('productid', this.coach_product_id);
    }
 
    params.append('interval', e.target.dataset.interval);
    
    Rails.ajax({
      type: 'POST',
      url: '/admin/organisations/retrieve-subscription-plans',
      data: params,
      success: (result) => {
        controller.subscriptionPlanTarget.value = result.local_plan_id;
        this.original_amount = result.original_amount
      }
    });
  }

  subscription_plan(e){
    if($('#subscription_coupon_code').val() !== ''){
      $('#subscription_coupon_code').val('');
      this.couponErrorTarget.innerHTML = `<div class="warning small-margin-bottom-gutter with-icon" id="coupon-error" role="alert">Your coupon code has been withdrawn since you have changed the payment frequence. Please apply the code again.</div>`;
      this.couponCheckButtonTarget.innerHTML = "Apply";
      this.couponCheckButtonTarget.disabled = false;
    }
    const controller = this;
    
    var params = new FormData();
    if(this.hasQuantityTarget){
      params.append('sequence', this.quantityTarget.value);
      params.append('productid', e.target.dataset.productid);
    }else{
   
      if(this.categoryTargets[1].checked){
        this.coach_product_id = e.target.dataset.coachsingleproductid;
      }else{
        this.coach_product_id = e.target.dataset.coachfamilyproductid;
      }
      params.append('productid', this.coach_product_id);
    }
  
    params.append('interval', e.target.dataset.interval);
    
    Rails.ajax({
      type: 'POST',
      url: e.target.dataset.url,
      data: params,
      success: (result) => {
        controller.subscriptionPlanTarget.value = result.local_plan_id;
        this.subscriptionPriceTarget.innerHTML = result.amount/100;
        this.original_amount = result.original_amount
        this.subscription_plan_id = result.planid
      }
    });
  }

  couponCheck(e){
    if(e) e.preventDefault();
    var params = new FormData();
    params.append('coupon_code', $('#subscription_coupon_code').val().trim());
    params.append('price_info', this.original_amount/100);
    params.append('subscription_plan_id', this.subscription_plan_id);
    
    Rails.ajax({
      type: 'POST',
      url: '/subscriptions/coupon-check',
      data: params,
      success: (result) => {
        this.subscriptionPriceTarget.innerHTML = result.discounted_price;
        this.couponErrorTarget.innerHTML = '';
        this.couponCheckButtonTarget.innerHTML = "Applied";
        this.couponCheckButtonTarget.disabled = true;
      },
      error: (error) => {
        this.subscriptionPriceTarget.innerHTML = error.original_price;
        this.couponErrorTarget.innerHTML = `<div class="warning small-margin-bottom-gutter with-icon" id="coupon-error" role="alert">${error.message}</div>`;
        $('#subscription_coupon_code').val('');
        this.couponCheckButtonTarget.innerHTML = "Apply";
        this.couponCheckButtonTarget.disabled = false;
      }
    });
  }

  async retrieveUpcomingInvoice(subscriptionId, new_subscription_plan_id, quantity) {
    const response = await fetch(this.invoiceValue, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        subscription_id: subscriptionId,
        subscription_plan_id: new_subscription_plan_id,
        quantity: quantity
      }),
    });
    const invoice = await response.json();
    this.invoice = invoice.invoice;
    return invoice;
  }

}