import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = [
    "step1",
    "step2",
    "step3",
    "step4",
    "wrapper",
    "nameYourProgramInput",
    "sportInput",
    "ageGroupInput",
    "sessionInput",
    "nameYourProgramHtml",
    "sportHtml",
    "ageGroupHtml",
    "sessionHtml",
    "coachHtml",
    "assignCoachesSelect",
    "nextButton",
    "step1Element",
    "step2Element",
  ];
  static values = { currentStep: Number };

  // initialize() {
  //   this.initRender();
  // }

  connect() {
    this.currentStepValue = 1;
    this.renderPage();
    this.nameYourProgramInputTarget.addEventListener("input", (e) => {
      this.step1Validation(e.target.value);
    });
    this.step1Validation(this.nameYourProgramInputTarget.value);
  }

  step1Validation(value) {
    if (!!value && this.step1ElementTarget.classList.contains("active")) {
      this.nextButtonTarget.hidden = false;
    } else if (
      !!!value &&
      this.step1ElementTarget.classList.contains("active")
    ) {
      this.nextButtonTarget.hidden = true;
    }
  }

  renderNext() {
    if (this[`hasStep${this.currentStepValue + 1}Target`]) {
      this.currentStepValue++;
      this.renderPage();
    }
  }

  renderPrev() {
    if (this[`hasStep${this.currentStepValue - 1}Target`]) {
      this.currentStepValue--;
      this.renderPage();
    }
  }

  renderPage() {
    $(this.wrapperTarget).find(".render-wrapper.create-program").hide();
    $(this[`step${this.currentStepValue}Target`]).fadeIn("600");
  }

  renderData() {
    let age_groups =
      this.ageGroupInputTarget.getElementsByClassName("is-active");
    var sessions = "";
    var sessions_index = 1;
    var coachs = "";
    $(this.nameYourProgramHtmlTarget).text(
      $(this.nameYourProgramInputTarget).val()
    );
    $(this.sportHtmlTarget).text(this.sportInputTarget.innerHTML);
    $(this.ageGroupHtmlTarget).html(
      age_groups.length > 0
        ? Array.prototype.map
            .call(age_groups, (element) => element.textContent)
            .join(" ")
        : "No age group selected"
    );
    this.sessionInputTargets.forEach(function add(element) {
      if (element.style.display == "none") return;
      sessions += `<div class="pane-list-item grid-x grid-margin-x align-middle">
                      <div class="cell shrink">
                        <p>${sessions_index++}.</p>
                      </div>
                      <div class="cell auto">
                        <div class="grid-x pane-item align-middle">
                          <p>${
                            element.getElementsByClassName("display-title")[0]
                              .innerText
                          }</p>
                        </div>
                      </div>
                    </div>`;
    });
    $(this.sessionHtmlTarget).html(sessions);
    this.assignCoachesSelectTarget
      .querySelectorAll(".item")
      .forEach(function add(element, index) {
        coachs += `<div class="cell medium-2">
                    <div class="user-info-pane grid-x grid-padding-x align-middle">
                      <div class="cell shrink">
                        <div class="avatar-wrapper" style="background-image: url(${
                          element.getElementsByTagName("img")[0].src
                        })"></div>
                      </div>
                      <div class="cell auto">
                        <div class="grid-x pane-item align-middle">
                          <div class="title">${
                            element.firstChild.nextSibling.data
                          }</div>
                          <div class="subtitle">Coach</div>
                        </div>
                      </div>
                    </div>
                  </div>`;
      });
    if (this.hasCoachHtmlTarget) {
      $(this.coachHtmlTarget).html(
        coachs ? coachs : "<p>No coach selected</p>"
      );
    }
  }
}
