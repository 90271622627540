import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ['wrapper'];
  
  connect() {
    this.initSlider();
    this.sliderChange();
  }

  initSlider() {
    Foundation.reflow($('.slider'), 'slider');
    let wrapper = $(this.wrapperTarget);
    var event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });
    wrapper.find('input')[0].dispatchEvent(event);
    $('[data-number-pane]').text(wrapper.find('input').val());
  }

  sliderChange() {
    let wrapper = $(this.wrapperTarget);
    wrapper.on('moved.zf.slider', function() {
      var event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      $(this).find('input')[0].dispatchEvent(event);
      $('[data-number-pane]').text(wrapper.find('input').val());
    });
  }
}
