import { Controller } from "stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = { placeholder: String }

  connect(){
    new SlimSelect({
      select: this.element,
      placeholder: this.placeholderValue,
    })
  }
}