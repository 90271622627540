import { Controller } from "stimulus";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.min.css";

export default class extends Controller {
  static values = {
    licensenumber: Number,
  };

  connect() {
    new TomSelect(this.element, {
      plugins: ["remove_button"],
      create: true,
      // maxItems: this.licensenumberValue,
      onItemAdd: function () {
        this.setTextboxValue("");
        this.refreshOptions();
      },
      render: {
        option: function (data, escape) {
          return (
            '<div class="d-flex"><span>' +
            escape(data.value) +
            '</span><span class="ms-auto text-muted">' +
            escape(data.date) +
            "</span></div>"
          );
        },
        item: function (data, escape) {
          return "<div>" + escape(data.value) + "</div>";
        },
      },
      plugins: {
        remove_button: {
          title: "Remove this item",
        },
      },
    });
  }
}
