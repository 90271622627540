import { Controller } from "stimulus"
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['payButton', 'paymentMethod']
  static values = { stripe: String }

  async initialStripe(){
    this.stripe = await loadStripe(this.stripeValue);
    const controller = this
    let elements = this.stripe.elements();
    let style = {
      base: {
        color: "#000000",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#BEBEBE"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    this.cardNumber = elements.create('cardNumber', {
      style: style
    });
    let cardNumber = this.cardNumber

    let cardExpiry = elements.create('cardExpiry', {
      style: style
    });

    let cardCvc = elements.create('cardCvc', {
      style: style
    });

    cardNumber.mount('#card-number');
    cardExpiry.mount('#card-expiry');
    cardCvc.mount('#card-cvc');

    [cardNumber, cardExpiry, cardCvc].forEach(element => {
      element.on("change", function (event) {
        // Disable the Pay button if there are no card details in the Element
        controller.payButtonTarget.disabled = event.empty;
        document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
        // if(event.error) {
        //   $("#card-error").text(event.error.message);
        //   $(".form-group-v.strip-form-group").addClass('error');
        // }else {
        //   $("#card-error").text("");
        //   $(".form-group-v.strip-form-group").removeClass('error');
        // }
      });
    })
  }

  connect(){
    this.initialStripe()
  }

  confirmCard(e){
    e.preventDefault()
    this.createPaymentMethod({});
  }

  createPaymentMethod({ isPaymentRetry, invoiceId }) {
    const controller = this;
    let subscription_plan_id = this.subscription_plan_id
  
    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: controller.cardNumber
      })
      .then((result) => {
        if (result.error) {
          controller.showError(result.error.message);
        } else {
          if (isPaymentRetry) {
            // Update the payment method and retry invoice payment
            // this.retryInvoiceWithNewPaymentMethod(
            //   customerId,
            //   result.paymentMethod.id,
            //   invoiceId,
            //   subscription_plan_id
            // );
          } else {
            // Create the subscription
            controller.paymentMethodTarget.value = result.paymentMethod.id
            controller.element.submit()
          }
        }
      })
  }

  loading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.payButtonTarget.disabled = true;
      this.spinnerTarget.classList.add("is-paying");
      // document.querySelector("#spinner").classList.remove("hide");
      // document.querySelector("#button-text").classList.add("hide");
    } else {
      this.payButtonTarget.disabled = false;
      this.spinnerTarget.classList.remove("is-paying");
      // document.querySelector("#spinner").classList.add("hide");
      // document.querySelector("#button-text").classList.remove("hide");
    }
  };
}