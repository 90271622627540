import {
  Controller
} from "stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  static values = { msg: String, type: String }

  connect(){
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    var icon = ''
    switch(this.typeValue) {
      case 'notice':
        icon = 'success';
        break;
      case 'success':
        icon = 'success';
        break;
      case 'alert':
        icon = 'warning';
        break;
      default:
        icon = this.typeValue? this.typeValue : 'warning';
    }
    Toast.fire({
      icon: icon,
      title: this.msgValue
    })
  }
}
