import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selector", "placeholder", "file"];

  connect() {
    this.showPlaceholder();
    const controller = this;
    this.selectorTarget.addEventListener("change", (e) => {
      controller.showPlaceholder();
    });

    this.fileTarget.addEventListener("change", (e) => {
      // File size limit: 500MB
      if (e.target.files[0].size / (1024 * 1024) > 500) {
        e.preventDefault();
        controller.showLargeFileError(e.target);
        e.target.value = null;
      } else {
        let error_message = document.querySelector("#file-size-error");
        error_message.parentElement.removeChild(error_message);
      }
    });
  }

  showPlaceholder() {
    if (this.selectorTarget.value === "lottie") {
      this.placeholderTarget.parentElement.parentElement.hidden = false;
    } else {
      this.placeholderTarget.parentElement.parentElement.hidden = true;
    }
  }

  showLargeFileError(targetElement) {
    let error_message = document.createElement("small");
    error_message.className = "error";
    error_message.id = "file-size-error";
    error_message.style = "justify-content: start";
    error_message.textContent = "Please upload a file within the 500 MB limit";

    let error = document.querySelector("#file-size-error");
    if (!error)
      targetElement.parentElement.parentElement.appendChild(error_message);
  }
}
