import { Controller } from "stimulus";
import Swal from "sweetalert2";
import Shepherd from "shepherd.js";
import $ from "jquery";
import { Dropdown } from "bootstrap";

export default class extends Controller {
  static targets = ["dashboard", "training", "resource", "coaches", "settings"];
  static values = {
    show: Boolean,
    success: Boolean,
    coach: Boolean,
    api: String,
    userRoleId: Number,
  };

  connect() {
    if (window.screen.width >= 1025) {
      return;
    }

    const controller = this;
    if (this.successValue) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your payment was successful",
        confirmButtonText: "Continue",
        showClass: {
          popup: "alert-popup alert-show",
        },
        customClass: {
          confirmButton: "button primary",
        },
        allowEnterKey: false,
        allowEscapeKey: false,
      }).then(() => {
        controller.tutorial();
      });
    } else if (this.showValue) {
      controller.tutorial();
    }
  }

  tutorial() {
    let dashboard, training, resource, coaches, settings;
    dashboard = this.dashboardTarget;
    training = this.trainingTarget;
    resource = this.resourceTarget;
    coaches = this.hasCoachesTarget ? this.coachesTarget : false;
    let dashboardText = this.coachValue
      ? "This is your dashboard here you will find your favourite drills/sessions/programs."
      : "Here you will find your allocated training program or session by the school/ club. You can also add your favourite drills/ sessions/programs to appear here.";

    const box = document.querySelector("body");

    if (this.hasSettingsTarget) {
      settings = document.createElement("div");

      settings.setAttribute(
        "style",
        "width: 280px; height: 350px; position: fixed; bottom: 10px; left: 10px;"
      );

      box.appendChild(settings);
    }

    const dropdownElementList = this.element.querySelector(".dropdown-toggle");
    const dropdown = new Dropdown(dropdownElementList);

    const support = document.createElement("div");

    support.setAttribute(
      "style",
      "width: 75px; height: 75px; position: fixed; bottom: 10px; right: 10px;"
    );

    box.appendChild(support);

    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark tutorial",
        scrollTo: true,
        useModalOverlay: true,
      },
      useModalOverlay: true,
    });

    tour.addStep({
      id: "dashboard-step",
      title: "This is your dashboard",
      text: dashboardText,
      attachTo: {
        element: dashboard,
        on: "bottom-end",
      },
      beforeShowPromise: () => {
        return new Promise(function (resolve) {
          $("#side-nav-offcanvas").foundation("open");
          setTimeout(resolve, 500);
        });
      },
      classes: "example-step-extra-class",
      buttons: [
        {
          text: "Skip",
          classes: "skip",
          action: tour.complete,
        },
        {
          text: "Next",
          classes: "button light-bg next",
          action: tour.next,
        },
      ],
    });

    tour.addStep({
      id: "training-step",
      title: "Training",
      text: "<p>Your programs, sessions and drills for the sport are found here. These can be <a href='https://www.google.com'>searched & filtered</a> using the icons at the top.</p> <p>Customise your own programs or sessions – <a href='https://www.google.com'>add/remove/re-order</a> or <a href='https://www.google.com'>create your own program</a> and <a href='https://www.google.com'>session</a>.</p> <p>Click here for some helpful <a href='https://www.google.com'>how to use guides</a> in the <a href='https://www.google.com'>Go2support pages</a>.</p>",
      attachTo: {
        element: training,
        on: "bottom-end",
      },
      classes: "example-step-extra-class",
      buttons: [
        {
          text: "Skip",
          classes: "skip",
          action: tour.complete,
        },
        {
          text: "Next",
          classes: "button light-bg next",
          action: tour.next,
        },
      ],
    });

    tour.addStep({
      id: "resources-step",
      title: "Resources",
      text: "These are resources we have developed and found useful and we will continue to expand and update this information. These resources are beneficial to coaches and team members.",
      attachTo: {
        element: resource,
        on: "bottom-end",
      },
      classes: "example-step-extra-class",
      buttons: [
        {
          text: "Skip",
          classes: "skip",
          action: tour.complete,
        },
        {
          text: "Next",
          classes: "button light-bg next",
          action: tour.next,
        },
      ],
    });

    if (coaches) {
      tour.addStep({
        id: "coaches-step",
        title: "Coaches",
        text: "<p>This area allows the admin user to <a href='https://support.go2coach.com.au/hc/en-us/articles/4601730318991-How-to-manage-coaches-within-your-School-or-Club' target='_blank'>invite and remove coaches</a> and keep up to date with how many licences you have available.</p><p>Learn how to <a href='https://support.go2coach.com.au/hc/en-us/articles/4601723645455-How-to-assign-coaches-to-existing-programs-sessions' target='_blank'>assign programs or sessions</a> for coaches to follow.</p>",
        attachTo: {
          element: coaches,
          on: "bottom-end",
        },
        classes: "example-step-extra-class",
        buttons: [
          {
            text: "Skip",
            classes: "skip",
            action: tour.complete,
          },
          {
            text: "Next",
            classes: "button light-bg next",
            action: tour.next,
          },
        ],
      });
    }

    tour.addStep({
      id: "support-step",
      title: "We are here to help.",
      text: "<p>Please check our <a href='https://support.go2coach.com.au/hc/en-us' target='_blank'>Go2support page</a>, or ask our friendly bot a question or contact our Go2support team</p>",
      attachTo: {
        element: support,
        on: "top-end",
      },
      classes: "support-step",
      buttons: [
        {
          text: "Skip",
          classes: "skip",
          action: tour.complete,
        },
        {
          text: `${settings ? "Next" : "Got it!"}`,
          classes: "button light-bg next",
          action: tour.next,
        },
      ],
    });

    if (settings) {
      tour.addStep({
        id: "settings-step",
        title: "Settings",
        beforeShowPromise: () => {
          return new Promise(function (resolve) {
            dropdown.show();
            resolve();
          });
        },
        text: "<p>Under your Profile Badge is the settings.</p><ul><li>Increase/ decrease licence numbers (<a href='https://support.go2coach.com.au/hc/en-us/articles/4625765459983-How-do-I-manage-my-School-Subscription-' target='_blank'>School</a> or <a href='https://support.go2coach.com.au/hc/en-us/articles/4620735979535-How-do-I-manage-my-Club-Subscription-' target='_blank'>Club</a>)</li><li>Change the account nominated <a href='https://support.go2coach.com.au/hc/en-us/articles/4601648456719-How-do-I-change-the-email-address-associated-with-my-Go2coach-app-account-' target='_blank'>email<a></li><li>Change <a href='https://support.go2coach.com.au/hc/en-us/articles/4601663858447-How-do-I-update-and-change-my-payment-details-' target='_blank'>payment details</a> (monthly, yearly or credit/debit card)</li></ul>",
        attachTo: {
          element: settings,
          on: "top-start",
        },
        buttons: [
          {
            text: "Skip",
            classes: "skip",
            action: tour.complete,
          },
          {
            text: "Got it!",
            classes: "button light-bg next",
            action: tour.next,
          },
        ],
      });
    }

    tour.start();
    tour.on("complete", () => {
      dropdown.hide();
      support.remove();
      if (!!settings) {
        settings.remove();
      }
      fetch(`${this.apiValue}user_roles/${this.userRoleIdValue}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify({
          user_role: {
            tutorial: false,
          },
        }),
      });
      $("#side-nav-offcanvas").foundation("close");
      window.history.replaceState(null, null, window.location.pathname);
    });
  }
}
